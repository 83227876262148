require('../../node_modules/uikit/dist/js/uikit.min.js');
import UIkit from 'uikit';

//UIkit.slider('.uk-slider', {autoplay:true});
/*
$(document).on('click', ".mobile-menu-icon", function ( event ) {
    if($(this).find('input').is(':checked')) {
        $('.menu-container').addClass('open');
    } else {
        $('.menu-container').removeClass('open');
    }
} )
*/

$(document).on('click', ".mobile-menu-icon", function ( event ) {
    $('.mobile-menu-icon label').removeClass('return');
    if($(this).find('input').is(':checked')) {
        $('.mobile-menu-block').addClass('open');
    } else {
        $('.mobile-menu-block').removeClass('open');
    }
} )



$(document).on('click', ".mobile-menu-block.open .close-menu", function ( event ) {

    if($('.mobile-menu-icon').find('input').is(':checked')) {
        $('.mobile-menu-icon label').addClass('return');
        $('.mobile-menu-icon input').prop('checked', false);
    } else {
        $('.mobile-menu-icon label').removeClass('return');
        $('.mobile-menu-icon input').prop('checked', true);
    }

} )



/*UIkit.scrollspy('.scroll-fade-fast', {cls:'uk-animation-fade', repeat: true, delay: 100});
UIkit.scrollspy('.scroll-fade-slow', {cls:'uk-animation-fade', repeat: true, delay: 250});*/
UIkit.scrollspy('.scroll-fade-fast', {cls:'uk-animation-fade', repeat: true, delay: 200});
UIkit.scrollspy('.scroll-fade-slow', {cls:'uk-animation-fade', repeat: true, delay: 200});
UIkit.scrollspy('.scroll-left', {cls:'uk-animation-slide-left', repeat: true, delay: 200});
UIkit.scrollspy('.scroll-right', {cls:'uk-animation-slide-right', repeat: true, delay: 200});
UIkit.scrollspy('.scroll-left-small', {cls:'uk-animation-slide-left-small', repeat: true, delay: 200});
UIkit.scrollspy('.scroll-right-small', {cls:'uk-animation-slide-right-small', repeat: true, delay: 200});

